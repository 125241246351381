import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import logo from '../../opi-logo.png';
import styles from './Navigation.module.css'; 
import { Button } from '@mui/material';
import { Logout } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../providers/AuthProvider';

function Navigation(props) {
  const [username, setUsername] = React.useState("");
  const authService = React.useContext(AuthContext);
  const isAuthenticated = authService.isAuthenticated();
  authService.getUser().then((userData) => {
    setUsername((userData.profile.name || userData.profile.email) + " (" + userData.profile.customer_type + ")");
  });
  
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="opi">
        <Toolbar variant="dense" className={styles['custom-toolbar']}>
        <Link to="/login" style={{height: "44px"}}><img alt="opi-logo" src={logo} className={styles["logo"]} /></Link>
          <span style={{ flexGrow: 1 }}></span>
          {/* <span className={styles["divider"]}></span>
          <Typography
            variant="h5"
            color="inherit"
            component="div"
            sx={{ flexGrow: 1 }}
          >
            {Constants.PROJECT_NAME}
          </Typography> */}

          {isAuthenticated && (
            <Typography variant="p" color="inherit" component="div">
              Welcome, {username}
            </Typography>
          )}
          {isAuthenticated && (
            <Button
              color="white"
              variant="outlined"
              startIcon={<Logout />}
              className={styles["logout-button"]}
              component={Link}
              to="/logout"
              disableElevation
            >
              Logout
            </Button>
          )}
          {/* {!isAuthenticated && (
            <Box>
              <Button
                color="white"
                variant="outlined"
                className={styles["logout-button"]}
                component={Link}
                to="/home"
                disableElevation
              >
                Login to Shop
              </Button>
            </Box>
          )} */}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Navigation;
