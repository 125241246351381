import React from "react";
import { Route } from "react-router-dom";
import { UnauthorizedAccessPage } from "../pages/UnauthorizedAccessPage";
import { AuthConsumer } from "../providers/AuthProvider";

export const PrivateRoute = ({ component, ...rest }) => {
    const renderFn = (Component) => (props) => (
        <AuthConsumer>
            {({ isAuthenticated, signinRedirect, isAuthorized }) => {
                if (!!Component && isAuthenticated()) {
                    if (isAuthorized()) {
                        return <Component {...props} />;
                    } else {
                        return <UnauthorizedAccessPage />;
                    }
                } else {
//                    if (rest.path === "/join") {
                        localStorage.setItem("client", "1");
//                    } else {
//                        localStorage.setItem("client", "2");
//                    }
                    signinRedirect();
                    return <span>Signing in...</span>;
                }
            }}
        </AuthConsumer>
    );

    return <Route {...rest} render={renderFn(component)} />;
};