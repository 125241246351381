import { ArrowForwardIosOutlined } from '@mui/icons-material';
import { Box, Button, Container, Paper, Typography } from '@mui/material';
import React from 'react';
import { Link } from "react-router-dom";
import Constants from '../Constants';
import { AuthContext } from '../providers/AuthProvider';
import styles from './LoginPage.module.css';

function LoginPage() {
//  const history = useHistory();
  const authService = React.useContext(AuthContext);
  const isAuthenticated = authService.isAuthenticated();

  if (isAuthenticated) {
//    history.push("/home");
  }

  return (
    <Container maxWidth="md">
      <Paper
        className={styles["login-page"]}
        elevation={0}
        sx={{
          mb: 4
        }}>
        <Typography
          variant="h4"
          align="center"
          color="inherit"
          component="div"
          sx={{
            mb:1
          }}
        >
          Join Today
        </Typography>
        <Typography
          variant="h6"
          align="center"
          color="inherit"
          component="div"
        >
          Welcome to {Constants.PROJECT_NAME} Shop
        </Typography>
      </Paper>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          '& > :not(style)': {
            m: 1
          },
        }}
      >
        {/* <Paper elevation={0}
          sx={{
            ml: 0,
            flexGrow: '1',
            textAlign: 'center'
          }}>
          <Button
            endIcon={<ArrowForwardIosOutlined />}
            variant="contained"
            color="opi"
            component={Link}
            to="/join"
            disableElevation
            size="large"
            sx={{
              fontSize: "x-large",
              width: "100%",
              height: "6rem"
            }}
          >
            Professional
          </Button>
        </Paper>
        <Paper elevation={0}
          sx={{
            flexGrow: '1',
            textAlign: 'center'
          }}>
          <Button
            endIcon={<ArrowForwardIosOutlined />}
            variant="contained"
            color="secondary"
            component={Link}
            to="/join-consumers"
            disableElevation
            size="large"
            sx={{
              fontSize: "x-large",
              width: "100%",
              height: "6rem"
            }}
          >
            Consumer
          </Button>
        </Paper> */}
        <Paper elevation={0}
          sx={{
            ml: 0,
            flexGrow: '1',
            textAlign: 'center'
          }}>
          <Button
            variant="contained"
            color="opi"
            component={Link}
            to="/home"
            disableElevation
            size="large"
            sx={{
              fontSize: "x-large",
              width: "100%",
              height: "6rem"
            }}
          >
            Join Now
          </Button>
        </Paper>
        <Paper elevation={0}
          sx={{
            flexGrow: '1',
            textAlign: 'center'
          }}>
          <Button
            variant="contained"
            color="secondary"
            component={Link}
            to="/home"
            disableElevation
            size="large"
            sx={{
              fontSize: "x-large",
              width: "100%",
              height: "6rem"
            }}
          >
            Sign In
          </Button>
        </Paper>
      </Box>
    </Container>
  );
}

export default LoginPage;
