import { Button, Container, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Component } from "react";
import { Link } from "react-router-dom";

export default class JoinProfessionalPage extends Component {
    render() {
        return (
            <Container maxWidth="md">
                <Paper
                    elevation={0}
                    sx={{
                        mb: 4
                    }}>
                    <Typography
                        variant="h4"
                        align="center"
                        color="inherit"
                        component="div"
                        style={{
                            color: '#c7003b'
                        }}
                    >
                        Professional
                    </Typography>
                </Paper>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        '& > :not(style)': {
                            m: 1
                        },
                        justifyContent: "center"
                    }}
                >
                    <Paper elevation={0}
                        sx={{
                            ml: 0,
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                        <Typography
                            variant="p"
                            align="center"
                            color="inherit"
                            component="div"
                            sx={{
                                mb: 1
                            }}
                        >
                            READY TO JOIN OUR SQUAD?
                        </Typography>
                        <Button
                            variant="outlined"
                            color="opi"
                            component={Link}
                            to="/home"
                            disableElevation
                            size="large"
                            sx={{
                            }}
                        >
                            Join Now
                        </Button>
                    </Paper>
                    <Paper elevation={0}
                        sx={{
                            ml: 0,
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                        <Typography
                            variant="p"
                            align="center"
                            color="inherit"
                            sx={{
                                mb: 1
                            }}
                            component="div"
                        >
                            ALREADY HAVE AN ACCOUNT?
                        </Typography>
                        <Button
                            variant="outlined"
                            color="opi"
                            component={Link}
                            to="/home"
                            disableElevation
                            size="large"
                            sx={{
                            }}
                        >
                            Sign in
                        </Button>
                    </Paper>
                </Box>
            </Container>
        );
    }
}
