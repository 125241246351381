import { Container, Paper, Typography } from "@mui/material";
import { Component } from "react";

export default class ProtectedConsumerPage extends Component {
    render() {
        return (
            <Container maxWidth="md">
                <Paper
                    elevation={0}
                    sx={{
                        mb: 4
                    }}>
                    <Typography
                        variant="h4"
                        align="center"
                        color="inherit"
                        component="div"
                        style={{
                            color: '#9c27b0'
                        }}
                    >
                        Dashboard for Consumer
                    </Typography>
                </Paper>
            </Container>
        );
    }
}
