import Navigation from "../components/navigation/Navigation";
import styles from './WithHeaderFooterLayout.module.css';

function WithHeaderFooterLayout({ children }) {
    return (
      <>
        <Navigation />
        <div className={styles["with-header-footer-container"]}>
          { children }
        </div>
      </>
    );
}

export default WithHeaderFooterLayout;
