import React from "react";
import { Redirect } from "react-router-dom";
import { AuthConsumer } from "../../providers/AuthProvider";

export const LogoutCallback = () => (
    <AuthConsumer>
        <Redirect to="/login" />
    </AuthConsumer>
);

/*{({ signoutRedirectCallback }) => {
    signoutRedirectCallback();
    return <span>loading</span>;
}}*/
