import { Redirect, Route, Switch } from 'react-router-dom';
import './App.css';
import LoginPage from './pages/LoginPage';
import WithHeaderFooterLayout from './layouts/WithHeaderFooterLayout';
import { Callback } from './components/auth/Callback';
import { Logout } from './components/auth/Logout';
import { LogoutCallback } from './components/auth/LogoutCallback';
import { SilentRenew } from './components/auth/SilentRenew';
import { PrivateRoute } from './routes/PrivateRoute';
import React from 'react';
import HomePage from './pages/HomePage';
import JoinProfessionalPage from './pages/JoinProfessionalPage';
import JoinConsumerPage from './pages/JoinConsumerPage';
import ProtectedProfessionalPage from './pages/ProtectedProfessionalPage';
import ProtectedConsumerPage from './pages/ProtectedConsumerPage';

class App extends React.Component {
  render() {
    return (<Switch>
      <Route exact path="/">
        <Redirect to="login" />
      </Route>
      <WithHeaderFooterLayout>
        <Route path="/login" component={LoginPage} />
        <Route exact={true} path="/signin/callback" component={Callback} />
        <Route exact={true} path="/logout" component={Logout} />
        <Route exact={true} path="/logout/callback" component={LogoutCallback} />
        <Route exact={true} path="/silentrenew" component={SilentRenew} />
        <PrivateRoute path="/home" component={HomePage} />
        <PrivateRoute path="/dashboard/professional" component={ProtectedProfessionalPage} />
        <PrivateRoute path="/dashboard/consumer" component={ProtectedConsumerPage} />
        <Route path="/join" component={JoinProfessionalPage} />
        <Route path="/join-consumers" component={JoinConsumerPage} />
      </WithHeaderFooterLayout>
      <Route>
        <Redirect to="/login" />
      </Route>
    </Switch>);
  }
}

export default App;
