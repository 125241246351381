import React from "react";
import { AuthContext } from "../providers/AuthProvider";
import { useHistory } from "react-router-dom";
import Constants from "../Constants";

function HomePage(props) {
    let history = useHistory();
    const authService = React.useContext(AuthContext);
    authService.getUser().then((userData) => {
        if(userData.profile.customer_type === "professional") {
            history.push('/dashboard/professional');
        } else if (userData.profile.customer_type === "consumer") {
            history.push('/dashboard/consumer');
        }
    });

    return (
        <div>{Constants.PROJECT_NAME} Shop</div>
    );
}

export default HomePage;
