module.exports = Object.freeze({
  PROJECT_NAME: process.env.REACT_APP_PROJECT_NAME,

  IDENTITY_CONFIG1: {
    authority: process.env.REACT_APP_AUTH_URL,
    client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID1,
    redirect_uri: window.location.origin + "/signin/callback",
    response_type: "code",
    scope: "openid profile",
    automaticSilentRenew: true,
    silent_redirect_uri: window.location.origin + "/silentrenew",
    loadUserInfo: true,
    post_logout_redirect_uri: window.location.origin + "/logout/callback",
  },

  IDENTITY_CONFIG2: {
    authority: process.env.REACT_APP_AUTH_URL,
    client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID2,
    redirect_uri: window.location.origin + "/signin/callback",
    response_type: "code",
    scope: "openid profile",
    automaticSilentRenew: true,
    silent_redirect_uri: window.location.origin + "/silentrenew",
    loadUserInfo: true,
    post_logout_redirect_uri: window.location.origin + "/logout/callback",
  },
  METADATA_OIDC: {},
});