import React from "react";
import { AuthConsumer } from "../../providers/AuthProvider";

export const Callback = () => (
    <AuthConsumer>
        {({ signinRedirectCallback }) => {
            signinRedirectCallback();
            return <span>Signing in...</span>;
        }}
    </AuthConsumer>
);