import { IDENTITY_CONFIG1, IDENTITY_CONFIG2 } from "../Constants"; // METADATA_OIDC
import { UserManager, WebStorageStateStore, Log } from "oidc-client";

export default class AuthService {
  UserManager1;
  UserManager2;

  constructor() {
    this.UserManager1 = new UserManager({
      ...IDENTITY_CONFIG1,
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    });

    this.UserManager2 = new UserManager({
      ...IDENTITY_CONFIG2,
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    });

    // Logger
    Log.logger = console;
    Log.level = Log.ERROR;

    this.UserManager1.events.addUserLoaded((user) => {
      if (window.location.href.indexOf("signin/callback") !== -1) {
        this.navigateToScreen();
      }
    });

    this.UserManager1.events.addSilentRenewError((e) => {
      console.log("silent renew error", e.message);
    });

    this.UserManager1.events.addAccessTokenExpired(() => {
      console.log("token expired");
      this.signinSilent();
    });

    this.UserManager2.events.addUserLoaded((user) => {
      if (window.location.href.indexOf("signin/callback") !== -1) {
        this.navigateToScreen();
      }
    });

    this.UserManager2.events.addSilentRenewError((e) => {
      console.log("slent renew error", e.message);
    });

    this.UserManager2.events.addAccessTokenExpired(() => {
      console.log("token expired");
      this.signinSilent();
    });

    if (window.location.href.indexOf("logout/callback") !== -1) {
      this.navigateToLogin();
    }

  }

  signinRedirectCallback = () => {
    if (this.getClientType() === "1") {
      this.UserManager1.signinRedirectCallback().then(() => {
        "";
      });
    } else {
      this.UserManager2.signinRedirectCallback().then(() => {
        "";
      });
    }
  };

  getUser = async () => {
    try {
      if (this.getClientType() === "1") {
        const user = await this.UserManager1.getUser();
        if (!user) {
          return await this.UserManager1.signinRedirectCallback();
        }
        return user;
      } else {
        const user = await this.UserManager2.getUser();
        if (!user) {
          return await this.UserManager2.signinRedirectCallback();
        }
        return user;
      }
    } catch (e) {
      return {};
    }
  };

  parseJwt = (token) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  };

  signinRedirect = () => {
    localStorage.setItem("redirectUri", window.location.pathname);
    if (this.getClientType() === "1") {
      this.UserManager1.signinRedirect({});
    } else {
      this.UserManager2.signinRedirect({});
    }
  };

  navigateToScreen = () => {
    window.location.replace("/home");
  };

  navigateToLogin = () => {
    window.location.replace("/login");
  };

  isAuthenticated = () => {
    if (this.getClientType() === "1") {
      const oidcStorage = JSON.parse(
        sessionStorage.getItem(
          `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID1}`
        )
      );

      return !!oidcStorage && !!oidcStorage.access_token;
    } else {
      const oidcStorage = JSON.parse(
        sessionStorage.getItem(
          `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID2}`
        )
      );

      return !!oidcStorage && !!oidcStorage.access_token;
    }
  };

  getAccessToken = () => {
    if (this.getClientType() === "1") {
      const oidcStorage = JSON.parse(
        sessionStorage.getItem(
          `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID1}`
        )
      );

      return oidcStorage && oidcStorage.access_token ? oidcStorage.access_token : "";
    } else {
      const oidcStorage = JSON.parse(
        sessionStorage.getItem(
          `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID2}`
        )
      );

      return oidcStorage && oidcStorage.access_token ? oidcStorage.access_token : "";
    }
  }

  signinSilent = () => {
    if (this.getClientType() === "1") {
      this.UserManager1.signinSilent()
        .then((user) => {
          console.log("signed in", user);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.UserManager2.signinSilent()
        .then((user) => {
          console.log("signed in", user);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  signinSilentCallback = () => {
    if (this.getClientType() === "1") {
      this.UserManager1.signinSilentCallback();
    } else {
      this.UserManager2.signinSilentCallback();
    }
  };

  createSigninRequest = () => {
    if (this.getClientType() === "1") {
      return this.UserManager1.createSigninRequest();
    } else {
      return this.UserManager2.createSigninRequest();
    }
  };

  logout = () => {
    if (this.getClientType() === "1") {
/*      this.UserManager1.signoutRedirect({
        id_token_hint: localStorage.getItem("id_token"),
      });*/
      sessionStorage.clear();
      this.UserManager1.clearStaleState();
      window.location.href = window.location.protocol + "//"+ window.location.host;
    } else {
      // this.UserManager2.signoutRedirect({
      //   id_token_hint: localStorage.getItem("id_token"),
      // });
      sessionStorage.clear();
      this.UserManager2.clearStaleState();
      window.location.href = window.location.protocol + "//"+ window.location.host;
    }
  };

  signoutRedirectCallback = () => {
    if (this.getClientType() === "1") {
      this.UserManager1.signoutRedirectCallback().then(() => {
        localStorage.clear();
        window.location.replace(process.env.REACT_APP_PUBLIC_URL);
      });
      this.UserManager1.clearStaleState();
    } else {
      this.UserManager2.signoutRedirectCallback().then(() => {
        localStorage.clear();
        window.location.replace(process.env.REACT_APP_PUBLIC_URL);
      });
      this.UserManager2.clearStaleState();
    }
  };

  isAuthorized = () => {
    return true;
  }

  getClientType = () => {
    return localStorage.getItem("client") || "2";
  }
}
